import React from 'react';

export default function Logo() {

  return (
    <div className='logo'>
    The{' '}
    <b>
        V
        <span className='logo-o'>
        <img src='/images/fingerprint192.gif' alt='o' />
        </span>
        ting
    </b> Company
    </div>
  );
}