export async function login(email, password) {
  if (process.env.NODE_ENV === 'production') {
    const response = await fetch('/api/v1/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });
    return await response.json();
  }
  await fakeNetwork();
  return {
    error: false,
    message: 'Login Successful',
  };
}

export async function forgotPassword(email) {
  if (process.env.NODE_ENV === 'production') {
    const response = await fetch('/api/v1/forgotPassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    });
    return await response.json();
  }
  await fakeNetwork();
  return {
    error: false,
    message: 'An email has been sent with a link to reset your password.',
  };
}

export async function resetPassword(code, email, password) {
  if (process.env.NODE_ENV === 'production') {
    const response = await fetch('/api/v1/resetPassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
        email,
        password,
      }),
    });
    return await response.json();
  }
  await fakeNetwork();
  return {
    error: false,
    message: 'Password reset successfully. Logging in...',
  };
}

export async function createAccountInquiry(email, reason) {
  if (process.env.NODE_ENV === 'production') {
    const response = await fetch('/api/v1/createAccountInquiry', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        reason,
      }),
    });
    return await response.json();
  }
  await fakeNetwork();
  return {
    error: false,
    message: 'Thanks for your interest. We\'ll reach out soon.',
  };
}

export async function createAccount(firstName, lastName, email, password, code) {
  if (process.env.NODE_ENV === 'production') {
    const response = await fetch('/api/v1/createAccount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
        email,
        firstName,
        lastName,
        password,
      }),
    });
    return await response.json();
  }
  await fakeNetwork();
  return {
    error: false,
    message: 'Account created. Logging in...',
  };
}

export async function confirmEmail(email, code) {
  if (process.env.NODE_ENV === 'production') {
    const response = await fetch('/api/v1/confirmEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        code,
      }),
    });
    return await response.json();
  }
  await fakeNetwork();
  return {
    error: false,
    message: 'Email address confirmed. Logging in...',
  };
}

export async function acceptInvite(code) {
  if (process.env.NODE_ENV === 'production') {
    const response = await fetch('/api/v1/acceptInvite', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
      }),
    });
    return await response.json();
  }
  await fakeNetwork();
  return {
    error: false,
    needsRegistration: true,
    email: 'test@votingcompany.com',
    message: 'Acceptance pending registration',
  };
}

export async function getVotes(id) {
  if (process.env.NODE_ENV === 'production') {
    const response = await fetch(`/api/v1/ballots/${encodeURIComponent(id)}/votes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  }
  await fakeNetwork();
  return {
    error: false,
    votes: [],
  };
}

// fake a cache so we don't slow down stuff we've already seen
let fakeCache = {};

export async function fakeNetwork(key) {
  if (!key) {
    fakeCache = {};
  }

  if (fakeCache[key]) {
    return;
  }

  fakeCache[key] = true;
  return new Promise(res => {
    setTimeout(res, Math.random() * 800);
  });
}
