import React from 'react';
import {
  Link,
  useNavigation,
} from 'react-router-dom';
import Logo from '../logo';

export default function NotFound() {
  const navigation = useNavigation();

  return (
    <div className='notFound'>
      <Logo />
      <div style={{textAlign: 'center'}}>
        <h1>
          404
        </h1>
        <p>
          Votes are being cast, but there's nothing to see here.
        </p>
        <h1>😶‍🌫️</h1>
        <p>
          <Link to='/'>
            <button className='blue btn'>Home</button>
          </Link>
        </p>
      </div>
    </div>
  );
}