import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import './index.css';
import Login from "./routes/login";
import ErrorPage from './ErrorPage';
import reportWebVitals from './reportWebVitals';
import ConfirmEmail from './routes/confirmEmail';
import CreateAccount from './routes/createAccount';
import CreateAccountInquiry from './routes/createAccountInquiry';
import ForgotPassword from './routes/forgotPassword';
import ResetPassword from './routes/resetPassword';
import VoteSearch from './routes/voteSearch';
import NotFound from './routes/notFound';
import AcceptInvite from './routes/acceptInvite';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
    errorElement: <ErrorPage />,
  }, {
    path: '/createAccount',
    element: window.location.search === '?inquiry=false' ? <CreateAccount /> : <CreateAccountInquiry />,
    errorElement: <ErrorPage />,
  }, {
    path: '/forgotPassword',
    element: <ForgotPassword />,
    errorElement: <ErrorPage />,
  }, {
    path: '/passwordReset',
    element: <ResetPassword />,
    errorElement: <ErrorPage />,
  }, {
    path: '/confirmEmail',
    element: <ConfirmEmail />,
    errorElement: <ErrorPage />,
  }, {
    path: '/acceptInvite',
    element: <AcceptInvite />,
    errorElement: <ErrorPage />,
  }, {
    path: '/ballots/:id/votes',
    element: <VoteSearch />,
    errorElement: <ErrorPage />,
  }, {
    path: '*',
    element: <NotFound />,
    errorElement: <ErrorPage />,
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
