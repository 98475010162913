import React, { useState } from 'react';
import {
  Link,
  Navigate,
  useNavigation,
} from 'react-router-dom';
import { resetPassword } from '../api';
import Logo from '../logo';

export default function ResetPassword() {
  const navigation = useNavigation();
  const [password, updatePassword] = useState('');
  const [confirmPassword, updateConfirmPassword] = useState('');
  const [errorMessage, updateErrorMessage] = useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const email = urlParams.get('email');

  if (!code || !email) {
    return (<Navigate to='/' />);
  }

  const doResetPassword = async (event) => {
    event.preventDefault();
    if (password.length < 8) {
      updateErrorMessage('Your password must be at least 8 characters long');
      return;
    }
    if (password !== confirmPassword) {
      updateErrorMessage('Passwords must match');
      return;
    }
    updateErrorMessage('');
    const response = await resetPassword(code, email, password);
    if (response.error) {
      updateErrorMessage(response.message);
    } else {
      window.location.href = '/';
    }
  }

  return (
    <div
      id="login"
      className={navigation.state === "loading" ? "loading" : ""}
    >
      <Logo />
      <div className='login-card card'>
        <div className='description'>
          Enter a password that is at least 8 characters
        </div>
        { errorMessage ? (
          <div className='status error'>
            {errorMessage}
          </div>
        ) : null}
        <form onSubmit={doResetPassword}>
          <input placeholder='New password' type='password' value={password} onChange={(event) => updatePassword(event.target.value)} autoComplete="new-password" />
          <input placeholder='Confirm new password' type='password' value={confirmPassword} onChange={(event) => updateConfirmPassword(event.target.value)} autoComplete="new-password" />
          <div className='clearfix'>
            <div className='action-buttons'>
              <Link to='/' className='link'>
                <button className='decline' type='button'>
                  Cancel
                </button>
              </Link>
              <button className='primary' type='submit'>
                Reset and Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}