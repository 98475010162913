import React, { useState } from 'react';
import {
  Link,
  useNavigation,
} from 'react-router-dom';
import { createAccountInquiry } from '../api';
import Logo from '../logo';

export default function CreateAccountInquiry() {
  const navigation = useNavigation();
  const [email, updateEmail] = useState('');
  const [reason, updateReason] = useState('');
  const [errorMessage, updateErrorMessage] = useState('');
  const [successMessage, updateSuccessMessage] = useState('');

  const doCreateAccountInquiry = async (event) => {
    event.preventDefault();
    updateErrorMessage('');
    updateSuccessMessage('');
    const response = await createAccountInquiry(email, reason);
    if (response.error) {
      updateErrorMessage(response.message);
    } else {
      updateSuccessMessage(response.message);
    }
  }

  return (
    <div
      id="login"
      className={navigation.state === "loading" ? "loading" : ""}
    >
      <Logo />
      <div className='login-card card'>
        <div className='description'>
          We're currently not taking new sign ups. Please enter your email address and why you'd like access and we'll follow up!
        </div>
        { errorMessage ? (
          <div className='status error'>
            {errorMessage}
          </div>
        ) : null}
        { successMessage ? (
          <div className='status success'>
            {successMessage}
          </div>
        ) : null}
        <form onSubmit={doCreateAccountInquiry}>
          <input placeholder='Email' type='email' value={email} onChange={(event) => updateEmail(event.target.value)} />
          <textarea placeholder='Reason for access' value={reason} onChange={(event) => updateReason(event.target.value)} />
          <button className='primary' type='submit'>
            Inquire
          </button>
        </form>
        <div className='text-centered login-link'>
          <Link to='/' className='link'>
            Already have an account?
          </Link>
        </div>
      </div>
    </div>
  );
}