import React, { useEffect, useState } from 'react';
import {
  Link,
  useNavigation,
  useParams,
} from 'react-router-dom';
import Logo from '../logo';
import { getVotes } from '../api';

export default function VoteSearch() {
  const navigation = useNavigation();
  const { id } = useParams();
  const [votes, setVotes] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchVotes = async () => {
      const response = await getVotes(id);
      setVotes(response.votes);
    }
    fetchVotes();
  }, [id]);

  const voteCards = votes.filter(vote => vote.words.some(word => word.startsWith(search))).slice(0, 10).map(vote => {
    return (
      <div key={vote.id} className='card centered spaced'>
        Vote cast
        <div className='smallText'>
          { vote.words.join(', ') }
        </div>
      </div>
    );
  });

  return (
    <div
      id="login"
      className={navigation.state === "loading" ? "loading" : ""}
    >
      <Logo />
      <div style={{textAlign: 'center'}}>
        <p>
          Search for votes
        </p>
        <p>
          <input
            className='search'
            placeholder='Enter the words associated with your vote'
            value={search}
            onChange={e => setSearch(e.target.value)}
            />
        </p>
        <p>
          {voteCards.length ? voteCards : 'No votes'}
        </p>
        <p>
          <Link to='/'>
            <button className='blue btn'>Home</button>
          </Link>
        </p>
      </div>
    </div>
  );
}