import React, { useState } from 'react';
import {
  Link,
  Navigate,
  useNavigation,
} from 'react-router-dom';
import { createAccount } from '../api';
import Logo from '../logo';

export default function CreateAccount({ code, email: initialEmail}) {
  const navigation = useNavigation();
  const isAcceptingInvite = !!initialEmail;

  const [email, updateEmail] = useState(initialEmail);
  const [firstName, updateFirstName] = useState('');
  const [lastName, updateLastName] = useState('');
  const [password, updatePassword] = useState('');
  const [confirmPassword, updateConfirmPassword] = useState('');
  const [errorMessage, updateErrorMessage] = useState('');
  const [successMessage, updateSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const doCreateAccount = async (event) => {
    event.preventDefault();
    updateErrorMessage('');
    updateSuccessMessage('');
    if (password.length < 8) {
      updateErrorMessage('Password must be at least 8 characters');
      return;
    }
    if (confirmPassword !== password) {
      updateErrorMessage('Passwords don\'t match');
      return;
    }
    setIsLoading(true);
    const response = await createAccount(firstName, lastName, email, password, code);
    setIsLoading(false);
    if (response.error) {
      updateErrorMessage(response.message);
    } else {
      updateSuccessMessage(response.message);
      if (isAcceptingInvite) {
        window.location.href = '/';
      }
    }
  }

  return (
    <div
      id="login"
      className={navigation.state === "loading" ? "loading" : ""}
    >
      <Logo />
      <div className='login-card card'>
        <div className='description text-centered'>
          Create a new account
        </div>
        { errorMessage ? (
          <div className='status error'>
            {errorMessage}
          </div>
        ) : null}
        { successMessage ? (
          <div className='status success'>
            {successMessage}
          </div>
        ) : null}
        <form onSubmit={doCreateAccount}>
          <div className='split-input'>
            <input placeholder='First name' type='text' onChange={(event) => updateFirstName(event.target.value)} />
            <input placeholder='Last name' type='text' onChange={(event) => updateLastName(event.target.value)} />
          </div>
          <input placeholder='Email' readOnly={isAcceptingInvite} disabled={isAcceptingInvite} type='email' value={email} onChange={(event) => updateEmail(event.target.value)} autoComplete='email' />
          <input placeholder='New password' type='password' onChange={(event) => updatePassword(event.target.value)} autoComplete="new-password" />
          <input placeholder='Confirm password' type='password' onChange={(event) => updateConfirmPassword(event.target.value)} autoComplete="new-password" />
          <button className='primary' type='submit' disabled={isLoading}>
            Sign Up
          </button>
        </form>
        { isAcceptingInvite ? null : (
          <div className='text-centered login-link'>
            <Link to='/' className='link'>
              Already have an account?
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}