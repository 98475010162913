import React, { useState } from 'react';
import {
  Link,
  useNavigation,
} from 'react-router-dom';
import { forgotPassword } from '../api';
import Logo from '../logo';

export default function ForgotPassword() {
  const navigation = useNavigation();
  const [email, updateEmail] = useState('');
  const [errorMessage, updateErrorMessage] = useState('');
  const [successMessage, updateSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const doForgotPassword = async (event) => {
    event.preventDefault();
    updateErrorMessage('');
    updateSuccessMessage('');
    setIsLoading(true);
    const response = await forgotPassword(email);
    setIsLoading(false);
    if (response.error) {
      updateErrorMessage(response.message);
    } else {
      updateSuccessMessage(response.message);
    }
  }

  return (
    <div
      id="login"
      className={navigation.state === "loading" ? "loading" : ""}
    >
      <Logo />
      <div className='login-card card'>
        <div className='description'>
          Enter your email address and if you have an account, we'll send you a link to reset your password.
        </div>
        { errorMessage ? (
          <div className='status error'>
            {errorMessage}
          </div>
        ) : null}
        { successMessage ? (
          <div className='status success'>
            {successMessage}
          </div>
        ) : null}
        <form onSubmit={doForgotPassword}>
          <input placeholder='Email' type='email' value={email} onChange={(event) => updateEmail(event.target.value)} />
          <div className='clearfix'>
            <div className='action-buttons'>
              <Link to='/' className='link' onClick={(event) => event.stopPropagation()}>
                <button className='decline' type='button'>
                  Cancel
                </button>
              </Link>
              <button className='primary' type='submit' disabled={isLoading}>
                Send Email
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}